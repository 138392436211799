import React from "react";
import {BrowserRouter,Route,Routes} from "react-router-dom";
import Login from './pages/Login/Login';
import SignIn from "./pages/SignIn/SignIn";
import Group from "./pages/Group/Group";
import Stripe from "./pages/Stripe/Stripe";
import MyGroup from "./pages/MyGroup/Mygroup";
import Loan from "./pages/Loan/Loan";
import Approve from "./pages/Approve/Approve";
import Invite from "./pages/Invite/Invite";
import Config from "./pages/Config/Config";
import Admin from "./pages/Admin/Admin";
import PrivateRoute from "./components/PrivateRoutes/PrivatesRoutes";
import AdminDetails from "./pages/Admin/AdminDetails";
import AdminLoans from "./pages/Admin/AdminLoans";
import CreditPaymentsPage from "./pages/CreditPayments/CreditPaymentsPage";

function WebRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/signin" element={<SignIn/>}></Route>
        <Route path="/signin/*" element={<SignIn/>}></Route>
        <Route path="/creditpayments" element={<CreditPaymentsPage/>}></Route>
        <Route path="/group" element={<Group/>}></Route>
        <Route path="/stripe" element={<Stripe/>}></Route>
        <Route path="/mygroup" element={<MyGroup/>}></Route>
        <Route path="/loan" element={<Loan/>}></Route>
        <Route path="/approve/*" element={<Approve/>}></Route>
        <Route path="/approve" element={<Approve/>}></Route>
        <Route path="/invite" element={<Invite/>}></Route>
        <Route path="/config" element={<Config/>}></Route>
        <Route path="/admin" element={<PrivateRoute component={Admin} />}></Route>
        <Route path="/admindetails" element={<PrivateRoute component={AdminDetails} />}></Route>
        <Route path="/adminloans/:id/:amount/:who/:consecutive" element={<PrivateRoute component={AdminLoans} />}></Route>

        
      </Routes>
    </BrowserRouter>
  );
}

export default WebRoutes;
