import axios from 'axios';
import { useState, useEffect } from 'react';
import { paths } from '../Paths';

function GetInfoGroup(idGroup, token) {

    let group, groupName, minimumAmount, frequency, approvedLoanInterest, leaderId;


    const getData = async () => {

        let dataRequest = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        const response = axios.get(`${paths.api}/api/groups`, dataRequest);

        return response;
    }

    const [myGroup, setMyGroup] = useState([]);


    

    useEffect(() => {
        getData().then((response) => {
            console.log(response);

            let dataGroup = response.data;
            console.log(dataGroup);
            let groupContent = dataGroup.content;
            console.log(groupContent);

            setMyGroup(groupContent);

        })
    }, [])


    console.log(myGroup);

    function FilterGroup() {

        for (let i = 0; i < myGroup.length; i++) {
            if (myGroup[i].id == idGroup) {
                groupName = myGroup[i].groupName;
                minimumAmount = myGroup[i].minimumAmount;
                frequency = myGroup[i].frequency;
                approvedLoanInterest = myGroup[i].approvedLoanInterest;
                leaderId = myGroup[i].leaderId;
            } else {
                console.log("grupo no encontrado")
                continue;
            }
        }

        group = { groupName, minimumAmount, frequency, approvedLoanInterest, leaderId };
        console.log(group);
    }
    FilterGroup();

    return group;

}

export { GetInfoGroup }