import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./CreditPayments.css";
import { paths } from "../../Paths";
import GetCreditMembers from "../../Functions/GetCreditMembers";
import Credits from "../../gateways/Credits";

function CreditPaymentForm() {
  let credits = new Credits();
  // Datos almacenados en el localStorage
  const token = JSON.parse(localStorage.getItem("token"));
  const idUser = JSON.parse(localStorage.getItem("userId"));
  const idGroup = JSON.parse(localStorage.getItem("groupId"));

  // Hooks
  const [transaccion, setTransaccion] = useState([]);
  const [monto, setMonto] = useState({
    amount: "",
  });
  const [select, setSelect] = useState();
  console.log(monto, parseInt(monto.amount));
  const [members, setMembers] = useState([]);

  function handleChangeSelect(e) {
    setSelect({ member: e.target.value });
  }

  const handleChange = (e) => {
    setMonto({
      ...monto,
      [e.target.name]: e.target.value,
    });
  };

  async function GetMembers() {
    let memberList = await GetCreditMembers();
    setMembers(memberList);
  }

  useEffect(() => {
    GetMembers();
  }, []);

  useEffect(() => {
    const insertOpera = async () => {
      const fecha = new Date();
      const hoy = fecha.toLocaleDateString();
      console.log(transaccion.id);
      const options = {
        method: "POST",
        url: `${paths.api}/api/groups/${idGroup}/users/${idUser}/transactions/${transaccion.id}/operations`,

        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          consecutive: '',
          documentId: "",
          value: `${monto.amount}`,
          transactionDate: hoy,
          paymentFrequency: "",
        },
      };
      monto.amount &&
      await axios
        .request(options)
        .then(function (response) {
          console.log(response);
          setMonto({ amount: "" });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    insertOpera();
  }, [transaccion]);

  

 /* const insertOperation = async (transactionId) => {
    console.log("operacion " + transactionId);
    const fecha = new Date();
    const hoy = fecha.toLocaleDateString();

    const options = {
      method: "POST",
      url: `${paths.api}/api/groups/${idGroup}/users/${idUser}/transactions/${transactionId}/operations`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        consecutive: idUser,
        documentId: "",
        value: monto.amount,
        transactionDate: hoy,
      },
    };
    await axios
      .request(options)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };*/

  const handleSubmit = async (e) => {
    e.preventDefault();

    let sendPayment = await credits.sendCreditPayment(
      parseInt(select.member),
      parseInt(monto.amount)
    );
    console.log(sendPayment);

    if (sendPayment == 200) {
      let options = {
        method: "POST",
        url: `${paths.api}/api/groups/${idGroup}/users/${idUser}/transactions/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          transactionName: "credit",
          isTypeOperation: true,
        },
      };

      await axios
        .request(options)
        .then(function (response) {
          setTransaccion(response.data);
          console.log(response.data.id);

         // insertOperation(response.data.id);

        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Transacción no fue exitosa",
        showConfirmButton: true,
      });
    }
  };

  return (
    <section className="ab ba">
      <div className="container-sub-title-stripe-credit">
        <h2 className="sub-title-credit">Pagos de Credito</h2>
      </div>
      <form className="container-credit-form" onSubmit={handleSubmit}>
        <section className="stripe-form-credit">
          <label className="label-form-credit">Monto a pagar</label>

          <input
            className="stripe-form-credit-input"
            required
            type="number"
            min={10}
            placeholder="Ingrese el monto"
            name="amount"
            label="Monto"
            onChange={handleChange}
            value={monto.amount}
          />

          <select
            required
            name="frequency"
            className="stripe-form-credit-input"
            onChange={handleChangeSelect}
          >
            <option value="" disabled selected>
              Miembro
            </option>
            {members &&
              members.map((member, id) => (
                <option key={id} value={member.id} name="member">
                  {member.userName}
                </option>
              ))}
          </select>
        </section>
        <div className="payment-info">
          <div>
            <h2>Monto a pagar:</h2>
            <h4>$1000</h4>
          </div>
          <div>
            <h2>Monto restante:</h2>
            <h4>$500</h4>
          </div>
          <div>
            <h2>Interés:</h2>
            <h4>5%</h4>
          </div>
          <div>
            <h2>N Cuotas</h2>
            <h4>5%</h4>
          </div>
          <br></br>
        </div>

        <button className="stripe-credit-button" type="submit">
          Enviar
        </button>
      </form>
    </section>
  );
}

export default CreditPaymentForm;
