import React from "react";
import AdminAmountGroups from "../../components/Admin/AdminAmountGroups/AdminAmountGroup";
import AdminLoanInfoUser from "../../components/Admin/AdminLoans/AdminLoanInfoUser";
import AdminLoanByUser from "../../components/Admin/AdminLoans/AdminLoansByUser";
import AdminLoansOperation from "../../components/Admin/AdminLoans/AdminLoansOperation";
import AdminResults from "../../components/Admin/AdminResults/AdminResults";
import Footer from "../../components/Footer/Footer";
import NavbarAdmin from "../../components/NavbarAdmin/NavbarAdmin";
import "./adminstructure.css";

const Admin = () => {
  return (
    <div className="main-container">
      <NavbarAdmin />
      <h2 className="admin-title">Administrador</h2>

      <h2 className="admin-subtitle">Prestamos</h2>
      <AdminLoansOperation />

      <h2 className="admin-subtitle">Depositos por grupo</h2>
      <AdminAmountGroups />


      

      <h2 className="admin-subtitle">Loans info user</h2>
      <AdminLoanInfoUser />

      <h2 className="admin-subtitle">loan by user</h2>
      <AdminLoanByUser />
      <Footer />
    </div>
  );
};

export default Admin;
