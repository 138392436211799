import * as React from "react";
import { useEffect, useState } from "react";
import GetloansByUser from "../../../Functions/GetLoansByUser";
import "../Admin.css";

function AdminLoanByUser() {
  const [loanByUser, setLoanByUser] = useState();
  console.log(loanByUser);

  async function Controller() {
    GetloansByUser()
      .then((response) => {
        console.log(response);
        setLoanByUser(response.data);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);

  return (
    <div className="container-admin-table table-size">
      <table className="admin-table">
       <thead>
          <tr>
            <th>Usuario</th>
            <th>Id</th>
            <th>Nombre Grupo</th>
            <th>N° GRUPO</th>
            <th>Deposito</th>
          </tr>
        </thead>
        <tbody>
       {loanByUser && loanByUser.map((info, i) => (
            <tr  key={i}>
              <td className="table-border-cell">{info[0]}</td>
              <td className="table-border-cell">{info[1]}</td>
              <td className="table-border-cell">{info[2]}</td>
              <td className="table-border-cell">{info[3]}</td>
              <td className="table-border-cell">{info[4]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

}
export default AdminLoanByUser;
