import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { paths } from "../../Paths";
import "../../components/Login/LoginForm.css";
import "./ApproveViewForm.css";

function ApproveStatement() {
  //url para decode
  const url = window.location.href;
  console.log(url);
  let token = url.split("?=")[1];
  console.log(token);

  let decodedToken = window.atob(token);
  console.log(decodedToken);
  let array = decodedToken.split(" ");
  console.log(array);
  let userId = array[0];
  console.log(userId);
  let groupId = array[1];
  console.log(groupId);
  let operationId = array[2];
  console.log(operationId);
  let whoApprove = array[3];
  console.log(whoApprove);
  let loanAmount = array[4];
  console.log(loanAmount);
  let whoRequest = array[5];
  console.log(whoRequest);

  const [password, setPassword] = useState();
  const [login, setLogin] = useState(false);

  function handleChange(e) {
    setPassword(e.target.value);
  }

  //iniciar con usuario prueba para obtener token temporal*/
  const approve = async (answer) => {
    console.log(answer);

    let tokenToApprove = localStorage.getItem("tokenToApprove");
    console.log(tokenToApprove);

    let loanApprove = answer === "accepted" ? true : false;
    console.log(loanApprove);

    let bodyData = { isApproved: loanApprove };
    console.log(bodyData);
    //aprobar o desaprobar la solicitud
    const options2 = {
      method: "POST",
      url: `${paths.api}/api/groups/${groupId}/operations/${operationId}/users/${userId}/approve`,
      headers: {
        Authorization: `Bearer ${tokenToApprove}`,
      },
      data: { isApproved: loanApprove },
    };
    await axios
      .request(options2)
      .then(function (response) {
        console.log(response.data);

        if (loanApprove) {
          Swal.fire({
            icon: "success",
            title: "Aprobado",
            text: "Aprobado con exito",
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "No aprobado",
            text: "Prestamo no aprobado",
            showConfirmButton: false,
          });
        }
        setTimeout(() => {
          window.close();
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Conexion",
          text: "El servidor esta presentando fallas",
          showConfirmButton: true,
        });
      });
  };

  async function PasswordVerify(e) {
    e.preventDefault();

    const options = {
      method: "POST",
      url: `${paths.api}/authenticate`,
      Headers: {
        "Content-Type": "aplication/json",
      },

      data: {
        userName: whoApprove,
        password: password,
      },
    };
    await axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        localStorage.setItem("tokenToApprove", response.data.token);

        setLogin(true);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Credenciales",
          text: "Las credenciales ingresadas son incorrectas",
          showConfirmButton: true,
        });
      });
  }

  return (
    <div>
      {login ? (
        <section>
          <div className="container-approve">
            <h2 className="title-approve text-white">
              ¿Deseas aprobar el préstamos del usuario {whoRequest} por un monto
              ${loanAmount}
            </h2>

            <button
              type="button"
              className="approve-button"
              onClick={() => approve("accepted")}
              value={"accepted"}
            >
              Aprobar
            </button>
            <button
              type="button"
              className="rejected-button"
              onClick={() => approve("rejected")}
              value={"rejected"}
            >
              {" "}
              Rechazar{" "}
            </button>
          </div>
        </section>
      ) : (
        <div>
          <h2 className="title-approve text-white">
            Ingrese sus credenciales para aprobar el Prestamo
          </h2>
          <form className="container-login-format" onSubmit={PasswordVerify}>
            <input
              required
              type="password"
              placeholder="Contraseña"
              name="password"
              value={password}
              onChange={handleChange}
              className="login-input  w-50"
            />
            <button className="login-button w-50">Ingresar</button>
          </form>
        </div>
      )}
    </div>
  );
}
export default ApproveStatement;
