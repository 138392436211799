import React from "react";
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from "cdbreact";

const DataTable = ({ operations }) => {
  console.log(operations);
  let operationsJSON = JSON.stringify(operations);
  console.log(operationsJSON);

  function testClickEvent(param) {
    alert("Row Click Event");
  }

  function ro() {
    return (
      operations &&
      operations.map((operations, i) => ({
        Id: operations.id,
        Fecha: operations.transactionDate,
        Documento: operations.documentId,
        Cuotas: operations.consecutive,
        Frecuencia: operations.paymentFrequency,
        Monto: operations.value,
        clickEvent: () => testClickEvent(1),
      }))
    );
  }

  const data = () => {
    return {
      columns: [
        {
          label: "Id",
          field: "id",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Id",
          },
          clickEvent: () => testClickEvent(1),
        },
        {
          label: "Fecha",
          field: "transactionDate",
          width: 270,
        },
        {
          label: "Documento",
          field: "documentId",
          width: 200,
        },
        {
          label: "Cuotas",
          field: "consecutive",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Frecuencia",
          field: "paymentFrequency",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Monto",
          field: "value",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: operations
        /*[ {
          id: 1,
          transactionDate: 'System Architect',
          documentId: 'Edinburgh',
          consecutive: '61',
          paymentFrequency: '2011/04/25',
          value: '$320',
          clickEvent: () => testClickEvent(1),
        }]*/
      
    };
  };

  return (
    <CDBContainer className="cdb-table">
      <CDBCard>
        <CDBCardBody>
          <CDBDataTable
            striped
            bordered
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={5}
            data={data}
            materialSearch={true}
            filter="office"
          />
        </CDBCardBody>
      </CDBCard>
    </CDBContainer>
  );
};

export default DataTable;
