import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { GetInfoGroup } from "../../Functions/GetInfoGroup";
import { GetMembersGroup } from "../../Functions/GetMembersGroup";
import "./CashPayment.css";
import DepositClass from "./cashPayment.class";
import { paths } from "../../Paths";

//instancia de la clase deposit
let deposit = new DepositClass();

function CashPayment({ transactionNameprop }) {
  console.log(transactionNameprop);

  //Datos almacenados en el localStorage
  const token = JSON.parse(localStorage.getItem("token"));
  console.log(token);
  const idUser = JSON.parse(localStorage.getItem("userId"));
  console.log(idUser);
  const idGroup = JSON.parse(localStorage.getItem("groupId"));
  console.log(idGroup);

  //hooks
  const [transaccion, setTransaccion] = useState([]);
  const [monto, setMonto] = useState({
    amount: "",
  });
  const [select, setSelect] = useState();

  //funcion para obtener la informacion del grupo
  let GroupInfo = GetInfoGroup(idGroup, token);
  console.log(GroupInfo.leaderId);
  console.log(idUser);

  //Llamado a la funcion que realiza la peticion para capturar los miembros de un grupo
  let members = GetMembersGroup(idGroup, token);
  console.log(members);

  //Manejador para el Select
  function handleChangeSelect(e) {
    setSelect({ member: e.target.value });
  }
  console.log(select);

  const handleChange = (e) => {
    setMonto({
      ...monto,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let depositFunction = deposit.updateDeposit(monto.amount, select.member);
    console.log(depositFunction);

    const insertransac = async () => {
      console.log(idUser);
      console.log(token);
      const options = {
        method: "POST",
        url: `${paths.api}/api/groups/${idGroup}/users/${idUser}/transactions/`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          transactionName: "consign",
          isTypeOperation: true,
        },
      };

      await axios
        .request(options)
        .then(function (response) {
          console.log(response);
          setTransaccion(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    insertransac();
  };

  useEffect(() => {
    const insertOpera = async () => {
      const fecha = new Date();
      const hoy = fecha.toLocaleDateString();
      console.log(transaccion.id);
      const options = {
        method: "POST",
        url: `${paths.api}/api/groups/${idGroup}/users/${idUser}/transactions/${transaccion.id}/operations`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          consecutive: "",
          documentId: "",
          value: monto.amount,
          transactionDate: hoy,
          paymentFrequency: "",
        },
      };
      await axios
        .request(options)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "genial",
            text: "Transaccion exitosa",
            showConfirmButton: true,
          });

          setMonto({ amount: "" });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    insertOpera();
  }, [transaccion]);

  if (GroupInfo.leaderId === idUser) {
    return (
      <section className="ab ba">
        <DepositClass />
        <div className="container-sub-title-stripe">
          <h2 className="sub-title-stripe">Pagos En Efectivo</h2>
        </div>
        <form className="container-cash-form" onSubmit={handleSubmit}>
          <section class="stripe-form-cash">
            <input
              class="stripe-form-cash-input"
              required
              type="number"
              min={10}
              placeholder="Ingrese el monto"
              name="amount"
              label="Monto"
              onChange={handleChange}
              value={monto.amount}
            />
            <h6 class="stripe-form-cash-text">
              *monto minimo 10 pesos mexicanos
            </h6>
            <select
              required
              name="frequency"
              className="loan-form-cash-input"
              onChange={handleChangeSelect}
            >
              <option value="" disabled selected>
                Miembro
              </option>
              {members.map((member, id) => (
                <option key={id} value={member.id} name="member">
                  {member.firstName} {member.lastName}
                </option>
              ))}
            </select>
          </section>
          <button className="stripe-button" type="submit">
            Enviar
          </button>
        </form>
      </section>
    );
  }
}

export default CashPayment;
