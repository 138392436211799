import './GroupInfo.css';
import GroupForm from '../GroupForm/GroupForm';
import React  from 'react';

function GroupInfo(){

    return(
        <section className='container-group-body'>
             
                <h2>Mi grupo</h2>
                <p className='group-info'>
                    Llena el formulario para crear tu grupo y las reglas
                    de ahorro que vas a tener con tus amigos
                </p>
                <GroupForm />
        </section>
    );
}

export default GroupInfo;