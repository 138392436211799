import React from 'react';
import ConfigForm from '../../components/Config/ConfigForm';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';

const Config = () => {
    return (
        <div className='main-container'>
            <Navbar />
            <h2 className='signin-title' >Perfil Usuario </h2>
            <ConfigForm />
            <Footer />
        </div>
    );
}

export default Config;
