import React, { useEffect, useState } from 'react';
import { GetDataUser } from '../../Functions/GetDataUser';
import { GetInfoGroup } from '../../Functions/GetInfoGroup';
import { GetLeaderName } from '../../Functions/GetLeaderName';
import { GetMembersGroup } from '../../Functions/GetMembersGroup';
import { GetTotalGroup } from '../../Functions/GetOperation';
import GetUserDeposit from '../../Functions/GetUserDeposit';
import './MyGroupInfo.css';

function MyGroupInfo() {
     
    //Guarda los datos almacenados localmente en variables
    const token = JSON.parse(localStorage.getItem('token'));
    const idUser = JSON.parse(localStorage.getItem('userId'));
    let idGroup = localStorage.getItem("groupId");

    //Llamado a la funcion que realiza la peticion para capturar los datos de usuario
    let user = GetDataUser(idUser, token);
    console.log(user);

    //Llamado a la funcion que realiza la peticion para capturar la informacion del grupo
    let group = GetInfoGroup(idGroup,token);
    console.log(group);
    console.log(group.leaderId);

    //Llamado a la funcion que realiza la peticion para capturar los miembros de un grupo
    let members = GetMembersGroup(idGroup,token);
    console.log(members);

    //Llamado a la funcion que realiza la peticion para extraer las transacciones que ha hecho un miembro del grupo
    let GetLeader = GetLeaderName(members, group.leaderId);
    console.log(GetLeader);

    //Llamado a la funcion que realiza la peticion para extraer las transacciones que ha hecho el grupo
    
    let operationGroup = GetTotalGroup(idGroup,token)
    console.log(operationGroup);    
      
    //Llamado a la funcion que realiza la peticion para extraer cuanto es el deposito del usuario logueado
    let userDeposit = GetUserDeposit(idUser, token)
    console.log(userDeposit.amountDeposited);

 



    return (
        <div className='container-main-mygroup' >
            
            <p className='mygroup-title'>{group.groupName}</p>

            <section className='container-mygroup-leader'>
                <p><span className='mygroup-leader'>Lider : </span>{GetLeader}</p>
            </section>

            <section className='container-mygroup-rules'>
                <article className='mygroup-rules'>
                    <p className='mygroup-rules-title'>Ahorro</p>
                    <p className='mygroup-rules-info'>${group.minimumAmount}</p>
                    <p className='mygroup-rules-info'>{group.frequency}</p>
                </article>
                <article className='mygroup-rules rules-border'>
                    <p className='mygroup-rules-title'>Interés</p>
                    <p className='mygroup-rules-info'>{group.approvedLoanInterest} %</p>
                    <p className='mygroup-rules-info'>Mensual</p>
                </article>
                <article className='mygroup-rules'>
                    <p className='mygroup-rules-title'>Préstamo</p>
                    <p className='mygroup-rules-info'>120%</p>
                    <p className='mygroup-rules-info'>Del ahorro</p>
                </article>
            </section>
            
            <section className='container-mygroup-leader'>
                <p><span className='mygroup-leader'>Tu deposito : </span>$ {userDeposit.amountDeposited}</p>
            </section>

            <h2 className='mygroup-member-title'>Miembros</h2>

            <section className='container-mygroup-members' >
                <button className='button-members' type='button' onclick="location.reload()"><a className= 'link-members' href="javascript:location.reload()">Ver miembros</a></button>

                {members.map((member,id)=>(
                    <article key={id} className='mygroup-member'>
                        <p className='mygroup-member-id'>{id+1}</p>
                        <p className='mygroup-member-name'> {member.firstName} {member.lastName} </p>
                    </article>
               ))}
                
                
                <article className='mygroup-total'>
                    <p><strong>Total Ahorro : </strong></p>
                    <p><strong>$ {operationGroup}</strong></p>
                </article>
            </section>
        </div>
    );
}

export default MyGroupInfo;


