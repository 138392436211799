import axios from "axios";
import { useState, useEffect } from "react";
import { paths } from "../Paths";

function GetDataUser(idUser, token) {
  console.log(idUser);
  const getData = async () => {
    let dataRequest = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = axios.get(`${paths.api}/api/users/${idUser}`, dataRequest);
    return response;
  };

  const [dataUser, setDataUser] = useState([]);

  useEffect(() => {
    getData().then((response) => {
      setDataUser(response.data);

      console.log(response);
    });
  }, []);

  return dataUser;
}

export { GetDataUser };
