import * as React from "react";
import { useEffect, useState } from "react";
import GetLoanOperations from "../../../Functions/GetLoanOperations";
import "../Admin.css";
import GetLoanUserInfo from "../../../Functions/GetLoanUserInfo";


function AdminLoanInfoUser() {
  const [loanInfoUser, setLoanInfoUser] = useState();
  console.log(loanInfoUser);

  async function Controller() {
    GetLoanUserInfo()
      .then((response) => {
        console.log(response);
        setLoanInfoUser(response.data);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);

  return (
    <div className="container-admin-table table-size">
      <table className="admin-table">
       <thead>
          <tr>
            <th>Usuario</th>
            <th>Id</th>
            <th>Nombre Grupo</th>
            <th>N° GRUPO</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
       {loanInfoUser && loanInfoUser.map((info, i) => (
            <tr  key={i}>
              <td className="table-border-cell">{info[0]}</td>
              <td className="table-border-cell">{info[1]}</td>
              <td className="table-border-cell">{info[2]}</td>
              <td className="table-border-cell">{info[3]}</td>
              <td className="table-border-cell">{info[4]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

}
export default AdminLoanInfoUser;
