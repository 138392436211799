import './Header.css'
import Logo from "../../img/LOGO.png";
import React, { useState } from "react";
import {
  Container,
  LogoContainer,
  Wrapper,
  Menu,
  MenuItem,
  MenuItemLink,
  MobileIcon,
} from "./Header.elements.js";

import {
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { IconContext } from "react-icons";
import { Link } from 'react-router-dom';
const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Container>
      <Wrapper>
        <IconContext.Provider value={{ style: { fontSize: "2em" } }}>
          <LogoContainer>
            <img className='header-logo' alt='logo' src={Logo} />
            <h1 className='header-name'>Cochinito App</h1>

          </LogoContainer>

          <MobileIcon onClick={() => setShowMobileMenu(!showMobileMenu)}>
            {showMobileMenu ? <FaTimes /> : <FaBars />}
          </MobileIcon>

          <Menu open={showMobileMenu}>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/" href='login' className='link-menu'>

                    Inicio
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/login" href='login' className='link-menu'>

                    Ingresar 
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/signin" href='signin' className='link-menu'>

                    Registrarse
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <a target='_blank' href='https://docs.google.com/presentation/d/e/2PACX-1vQxRXbVo2wv4BjtFtfog5iik6qM9hrZaZJ2TgF-dqTO0kgg2RWxCxmD1dS0CEb_DyczGhG4tNFl0ETX/pub?start=true&loop=true&delayms=5000' className='link-menu'>

                    Deck del proyecto
                  </a>
                </div>
              </MenuItemLink>
            </MenuItem>
          </Menu>
        </IconContext.Provider>
      </Wrapper>
    </Container>
  );
}

export default Header;