import axios from "axios";
import { paths } from "../Paths";
import Swal from "sweetalert2";

class Credits {
  token = JSON.parse(localStorage.getItem("token"));

  /**
   * Envia el pago de una cuota del credito
   */
  async sendCreditPayment(userId, amountDeposited) {
    const options = {
      method: "POST",
      url: `${paths.api}/api/payment`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      data: {
        userId: userId,
        amountDeposited: amountDeposited,
      },
    };
    try {
      let response = await axios.request(options);
      return response.status; 

    } catch (error) {
        return error.message;
    }
  }
}

export default Credits;
