import * as React from "react";
import { useEffect, useState } from "react";
import GetLoanOperations from "../../../Functions/GetLoanOperations";
import "../Admin.css";
import { Link } from "react-router-dom";


function AdminLoansOperation() {
  const [loanOperations, setLoanOperations] = useState();
  console.log(loanOperations);

  async function Controller() {
    GetLoanOperations()
      .then((response) => {
        console.log(response);
        setLoanOperations(response.data);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);

  return (
    <div className="container-admin-table table-size">
      <table className="admin-table groupp">
       <thead>
          <tr>
            <th>Usuario</th>
            <th>Fecha</th>
            <th>Documento</th>
            <th>Cuotas</th>
            <th>Frecuencia</th>
            <th>Monto</th>
            <th>Correo</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
       {loanOperations && loanOperations.map((amountGroups, i) => (
            <tr  key={i}>
              <td className="table-border-cell">{amountGroups[0]}</td>
              <td className="table-border-cell">{amountGroups[3]}</td>
              <td className="table-border-cell">{amountGroups[4]}</td>
              <td className="table-border-cell">{amountGroups[5] === "undefined" ? "" : amountGroups[5] }</td>
              <td className="table-border-cell">{amountGroups[6]}</td>
              <td className="table-border-cell">{amountGroups[7]}</td>
              <td className="table-border-cell">{amountGroups[8]}</td>
              {
                amountGroups[9] === "Aprobado" ? 
                <td className="table-border-cell"><Link to={`/adminloans/${amountGroups[2]}/${amountGroups[7]}/${amountGroups[1]}/${amountGroups[5]}`}><button className="operations-table-btn">Desembolsar</button></Link></td>
                :
                <td className="table-border-cell"></td>     
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

}
export default AdminLoansOperation;

