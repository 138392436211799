import React, { useRef , useEffect } from "react";
import { GetMembersGroup } from "../../../Functions/GetMembersGroup";
import { useParams } from "react-router";
import GetUserDeposit from "../../../Functions/GetUserDeposit";
import { useState } from "react";
import DepositClass from "../../CashPayment/cashPayment.class";
import GetLoansByUser from "../../../Functions/GetLoansByUser";
import axios from "axios";
import Swal from "sweetalert2";
import { paths } from "../../../Paths";


//instancia de la clase deposit
let deposit = new DepositClass();

const AdminShellOutMoney = () => {

  const [loanByUser, setLoanByUser] = useState();
  console.log(loanByUser);

  async function Controller() {
    let responseFilter = [];
    GetLoansByUser()
      .then((response) => {
        console.log(response);
       for(let i = 0 ; i<response.data.length ; i++ ){
        console.log(response.data[i][4] , id)
        if(response.data[i][4] === parseInt(id)){
          responseFilter.push({'id':response.data[i][2],'userName':response.data[i][1],'monto':response.data[i][5]})
        }
       }
       console.log(responseFilter.concat(members))
        setLoanByUser(responseFilter);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);

  

  const inputRef = useRef();

  const { id, amount, who , consecutive } = useParams();
  console.log(id, amount, who, consecutive);

  //Datos almacenados en el localStorage
  const token = JSON.parse(localStorage.getItem("token"));
  console.log(token)

  let members = GetMembersGroup(id, token);
  console.log(members);

  let userDeposit = GetUserDeposit(who, token);
  console.log(userDeposit.amountDeposited);

  let amountHook = [];

  for (let i = 0; i < members.length; i++) {
    amountHook.push({ [i]: "" });
  }
  console.log(amountHook);

  const [users, setUsers] = useState(amountHook);

  const handleChange = (e) => {
    setUsers({
      ...users,
      [e.target.name]: e.target.value,
    });
  };

  async function updateLoans(){
    const options = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      
    };

    let data = {loanPayable: amount}

    axios.put(`${paths.api}/api/groups/${id}/users/${who}/loans`,data, options)
        .then((res => {

            if (res.status === 200) {
            console.log(res.data);

                Swal.fire({
                    icon: 'success',
                    title: 'Genial...',
                    text: 'Registro Exitoso!'
                })
                
            }

        }
        ))
        .catch(function (error) {
            console.log(error);
        })
      }

  async function HandleSubmit(e) {
    
    e.preventDefault();

    /*let monto = e.target.monto.value
    let user = e.target.user.value

    console.log(user);
    console.log(monto, user, id);
    let depositFunction = await deposit.LoanShellOut(monto, user , id);
    console.log(depositFunction);

    updateLoans(monto);*/

    let depositFunction = await deposit.LoanShellOutAdmin(consecutive,who);
    console.log(depositFunction);

  }

  async function HandleShellOut(e) {
    e.preventDefault();
    let depositFunction = await deposit.LoanShellOut(amount, who, id);
    console.log(depositFunction);
  }

  function moneyAccounts() {
    if (parseInt(amount) < userDeposit.amountDeposited) {
      return (
        <section className="container-money-account">
          <div className="money-account">
            <h3> El ahorro del usuario es: $ {userDeposit.amountDeposited}</h3>
            <h3> El monto solicitado es : $ {amount}</h3>
            <section>
              Desea realizar la transaccion
              <button className="money-account-btn" onClick={HandleShellOut}> Si </button>
              <button className="money-account-btn"> No </button>
            </section>
          </div>
        </section>
      );
    } else {
      let missingAmount = amount - userDeposit.amountDeposited;
      console.log(missingAmount);

      return (
        <section className="container-money-account2" >
          <aside className="money-account">
            <h3 className="money-account-sub-title">El ahorro del usuario es:<span className="color-amount"> ${userDeposit.amountDeposited}</span></h3>
            <h3 className="money-account-sub-title"> El monto solicitado es :<span className="color-amount"> ${amount}</span></h3>
          </aside>
          {/*loanByUser &&
            loanByUser.map((member, i) => {
              return (
                <form key={i} className="container-money-account" onSubmit={HandleSubmit} >
                  <aside   className="section-money-account"  >
                    <label className="account-form-labelmoney" name='iduser' id="user">{member.userName}</label>
                    <label className="account-form-labelmoney" id="user">{member.monto}</label>
                    <input
                      name="user"
                      value={member.id}
                      required
                      className="input-userid"
                    />
                    <input
                      onChange={handleChange}
                      type="text"
                      id="amount"
                      name="monto"
                      placeholder="Monto"
                      value={users.i}
                      ref={inputRef}
                      member={member.id}
                      required
                    />
                    
                    <button className="money-account-form-btn"> Desembolsar</button>
                  </aside>

                </form>
              );
            })*/}
            <button onClick={HandleSubmit}>Desembolsar</button>
        </section>
      );
    }
  }

  return (
    <section>
      <h2 className="admin-title">Desembolsar Préstamo</h2>
      {moneyAccounts()}
    </section>
  );
};

export default AdminShellOutMoney;