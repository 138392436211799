import React from 'react';
import Footer from '../../components/Footer/Footer';
import MyGroupInfo from '../../components/MyGroup/MyGroupInfo';
import Navbar from '../../components/Navbar/Navbar';
import UserSession from '../../components/UserSession/UserSession';



function MyGroup() {

    return (
        <div className='main-container'>
            <UserSession />
            <Navbar />
            <MyGroupInfo />
            <Footer />
        </div>
    )
}

export default MyGroup;