import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UserSession.css';
import { GetDataUser } from '../../Functions/GetDataUser';

const UserSession = () => {

    //Datos almacenados en el localStorage 
    const token = JSON.parse(localStorage.getItem('token'));
    const idUser = JSON.parse(localStorage.getItem('userId'));

    let User = GetDataUser(idUser, token);
    console.log(User.userName)

    const [openMenu, setOpenMenu] = useState(false);
    console.log(openMenu);

    function setStateOpen() {
        setOpenMenu(!openMenu);
    }

    function Logout() {
        localStorage.clear();
        window.location.hash = 'no-back-button';
        window.location.hash = 'Again-No-back-button';

        window.onhashchange = function(){
            window.location.hash = "no-back-button";
        }

    }

    //funciones
    function OpenConfig() {
        return (
            <ul className='usersession-menu'>
                <li className='usersession-menu-btn'><Link to='/config' className='usersession-menu-btn'>Configuración</Link></li>
                <li className='usersession-menu-btn'><Link to='/' onClick={Logout} className='usersession-menu-btn'>Cerrar Sesión</Link></li>
            </ul>
        )
    }

    return (
        <section className='container-usersession'>
            <article className='usersession'>
                <button onClick={setStateOpen} className='usersession-btn'>{User.userName}</button>
                {(openMenu ? OpenConfig() : '')}
            </article>
        </section>
    );
}

export default UserSession;

