import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import SignInForm from '../../components/SignInForm/SignInForm';

function SignIn() {

    return (
        <div className='main-container'>
            <Header />
            <SignInForm />
            <Footer />
        </div>
    );
}

export default SignIn;