import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import GetOperations from "../../../Functions/GetOperations";
import "../Admin.css";
import DataTable from "./AdminTable";

const AdminOperations = () => {
  function testClickEvent(param) {
    alert("Row Click Event");
  }
  const [operations, setOperations] = useState();


  const columns = [
    {
      label: "Id",
      field: "id",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Id",
      },
      clickEvent: () => testClickEvent(1),
      flex: 1,
    },
    {
      label: "Fecha",
      field: "transactionDate",
      sort: "asc",
      width: 270,
      flex: 1,
    },
    {
      label: "Documento",
      field: "documentId",
      width: 200,
      flex: 1,
    },
    {
      label: "Cuotas",
      field: "consecutive",
      sort: "disabled",
      width: 100,
      flex: 1,
    },
    {
      label: "Frecuencia",
      field: "paymentFrequency",
      sort: "disabled",
      width: 150,
      flex: 1,
    },
    {
      label: "Monto",
      field: "value",
      sort: "asc",
      width: 100,
      flex: 1,
    },
  ];


  async function Controller() {
    GetOperations()
      .then((response) => {
        console.log(response);
        //setOperations({rows: response.data.content});
        setOperations(response.data.content)
        console.log(JSON.stringify(response.data.content));

      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="table-mui groupp">
      {operations && <DataGrid
        {...operations}
        title={"Lista de Usuários"}
        rows={operations}
        slots={{
          toolbar: CustomToolbar,
        }}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'asc' }, { field: 'amountDeposited', sort: 'desc' }],
          },
        }}
        checkboxSelection
        autoHeight
      />}
    </div>
  );

};

export default AdminOperations;
