import axios from 'axios';
import { paths } from '../Paths';

const GetCreditMembers = async () => {
    
    let token = JSON.parse(localStorage.getItem('token'));
    const idGroup = JSON.parse(localStorage.getItem("groupId"));
    console.log(idGroup);
    console.log(token)

        let dataRequest = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        let response = await axios.get(`${paths.api}/api/approved/${idGroup}`, dataRequest);
        console.log(response);

       return response.data
}

export default GetCreditMembers;
