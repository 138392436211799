import React, { useEffect, useState } from "react";
import GetLoans from "../../../Functions/GetLoans";
import "../Admin.css";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";


const AdminLoansDev = () => {

 /* const [loans, setLoans] = useState();
  console.log(loans);

  async function Controller() {
    GetLoans()
      .then((response) => {
        console.log(response);
        setLoans(response.data.content);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);

  return (
    <div className="container-admin-table">
      <table className="admin-table table-size">
       <thead>
          <tr>
            <th>Id</th>
            <th>Prestamo</th>
          </tr>
        </thead>
        <tbody>
       {loans && loans.map((Loans, i) => (
            <tr  key={i}>
              <td className="table-border-cell">{Loans.id}</td>
              <td className="table-border-cell">{Loans.loanPayable}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );*/
  const columns = [
    {
      field: 'id',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      resizable: false,
      headerAlign: 'center',
    },
    {
      field: 'loanPayable',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    },
  ];

  const [rows, setRows] = useState({});

  async function Controller() {
    GetLoans()
      .then((response) => {
        console.log(response.data)
        setRows(response.data.content);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);



  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="table-mui">
      {rows && <DataGrid
        {...rows}
        title={"Lista de Usuários"}
        rows={rows}
        slots={{
          toolbar: CustomToolbar,
        }}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'asc' }, { field: 'loanPayable', sort: 'asc' }],
          },
        }}
        checkboxSelection
        autoHeight
      />}
    </div>
  );
};

export default AdminLoansDev;
