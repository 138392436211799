import axios from "axios";
import React, { useState } from 'react';
import Swal from "sweetalert2";
import "./GroupForm.css";
import { paths } from "../../../Paths";

function GroupForm() {
  //Datos almacenados en el localStorage
  let userId = JSON.parse(localStorage.getItem("userId"));
  const token = JSON.parse(localStorage.getItem("token"));
  console.log(userId);
  console.log(token);

  //hook
  const [group, setGroup] = useState({
    groupName: "",
    minimumAmount: "",
    frequency: "",
    approvedLoanInterest: "",
    finishDate: "",
  });

  //handleChange para llenar el formulario
  const handleChange = (e) => {
    setGroup({
      ...group,
      [e.target.name]: e.target.value,
    });

    console.log(group.groupName);
    console.log(group.minimumAmount);
    console.log(group.frequency);
    console.log(group.finishDate);
    console.log(group.approvedLoanInterest);
  };

  console.log(group);

  const handleSubmit = async (e) => {
    e.preventDefault(e);

    console.log(userId);

    const options = {
      method: "POST",
      url: `${paths.api}/api/users/${userId}/groups`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        minimumAmount: group.minimumAmount,
        groupName: group.groupName,
        frequency: group.frequency,
        approvedLoanInterest: group.approvedLoanInterest,
        finishDate: group.finishDate,
      },
    };
    await axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          icon: "success",
          title: "Genial...",
          text: "Bienvenido!",
        });

        console.log(response.status);

        if (response.status === 200 || response.status === 201) {
          window.location.href = "/";
        }
      })
      .catch(function (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error de conexión!",
        });
      });
  };

  return (
    <form className="container-group-form" onSubmit={handleSubmit}>
      <label className="group-label" for="groupName">
        Nombre para mi grupo de ahorro
      </label>
      <input
        required
        type="text"
        id="groupName"
        name="groupName"
        placeholder="Nombre del grupo"
        value={group.groupName}
        onChange={handleChange}
        className="group-input"
      />

      <label className="group-label" for="minimumAmount">
        Monto de Ahorro
      </label>
      <article className="container-group-amount">
        <input
          required
          type="text"
          id="minimumAmount"
          name="minimumAmount"
          placeholder="Monto de ahorro"
          value={group.minimumAmount}
          onChange={handleChange}
          className="group-input group-select "
        />

        <select
          required
          name="frequency"
          className="group-input group-select"
          onChange={handleChange}
        >
          <option value="" disabled selected>
            Frecuencia
          </option>
          <option value="Semanal">Semanal</option>
          <option value="Quincenal">Quincenal</option>
          <option value="Mensual">Mensual</option>
        </select>
      </article>

      <label className="group-label" for="approvedLoanInterest">
        Porcentaje de interes de prestamo aprobado
      </label>
      <input
        required
        type="text"
        id="approvedLoanInterest"
        name="approvedLoanInterest"
        placeholder="% Interes de prestamo"
        value={group.approvedLoadInterest}
        onChange={handleChange}
        className="group-input"
      ></input>

      <label className="group-label" for="finishDate">
        Vigencia del grupo
      </label>
       <select
          required
          name="finishDate"
          id="finishDate"
          className="group-input"
          onChange={handleChange}
        >
          <option value="" disabled selected>
            Vigencia del grupo
          </option>
          <option value="3">3 meses</option>
          <option value="3">6 meses</option>
          <option value="9">9 meses</option>
          <option value="12">12 meses</option>
        </select>

      <section className="container-group-button">
        <button className="group-button" type="submit">
          Crear grupo
        </button>
      </section>
    </form>
  );
}

export default GroupForm;
