import React from 'react';
import CashPayment from '../../components/CashPayment/CashPayment';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import StripeForm from '../../components/StripeForm/StripeForm';
import UserSession from '../../components/UserSession/UserSession';
import './Stripe.css';

function Stripe() {
      return (
        <div className='main-container'>
            <UserSession />
            <Navbar />
            <h1 className='title-stripe'>Consignaciones</h1>
            <StripeForm />
            <CashPayment transactionNameprop={'consign'}/>
            <Footer />
        </div>
    );
}

export default Stripe;