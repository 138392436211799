import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { paths } from "../../Paths";
import "./SignInForm.css";

function SignInForm() {
  //variables
  let userId, array, groupName, groupId;

  //url para decode
  const url = window.location.href;
  console.log(url);
  let token = url.split("?=")[1];
  console.log(token);

  //validacion fecha
  let today = new Date();
  let year = today.getFullYear();

  console.log(today);
  console.log(year);

  //state para validar que los datos del formulario esten llenos
  const [error, setError] = useState(false);

  //state de la contraseña
  const [showPassword, setShowPassword] = useState(false);

  //state del formulario
  const [formulario, setFormulario] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "ROLE_USER",
    typeDocument: "",
    documentId: "",
    bornDate: "",
    gender: "",
    phone: "",
    password: "",
  });

  //desfragmetamos el state formulario
  const {
    userName,
    firstName,
    lastName,
    email,
    password,
    typeDocument,
    documentId,
    bornDate,
    gender,
    phone,
  } = formulario;

  //handleChange para llenar el formulario
  const handleChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });

    console.log(formulario.userName);
    console.log(formulario.typeDocument);
    console.log(formulario.gender);
  };

  //handleSubmit para enviar el formulario
  const handleSubmit = (e) => {

    e.preventDefault();
    if (
      userName.trim() == "" ||
      firstName.trim() == "" ||
      lastName.trim() == "" ||
      email.trim() == "" ||
      documentId.trim() == "" ||
      phone.trim() == "" ||
      password.trim() == ""
    ) {
      setError(true);

      Swal.fire({
        icon: "error",
        title: "Complete todos los campos",
        text: "Error!",
      });

      return;
    }

    setError(false);
    console.log(formulario.bornDate);
    console.log(formulario.bornDate.split("-"));
    console.log(formulario.bornDate.split("-")[0]);

    if (year - formulario.bornDate.split("-")[0] >= 18) {
      const insertarFour = async () => {
        const options = {
          method: "POST",
          url: `${paths.api}/register`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            userName: formulario.userName,
            firstName: formulario.firstName,
            lastName: formulario.lastName,
            email: formulario.email,
            role: formulario.role,
            typeDocument: formulario.typeDocument,
            documentId: formulario.documentId,
            bornDate: formulario.bornDate,
            gender: formulario.gender,
            phone: formulario.phone,
            password: formulario.password,
          },
        };
        await axios
          .request(options)
          .then(function (response) {
            console.log(response.status);
            if (response.status === 200 || response.status === 201) {
              if (
                window.location.href ===
                  `http://localhost:3000/signin/token?=${token}` ||
                window.location.href === `${paths.front}/signin/token?=${token}`
              ) {
                let decodedToken = window.atob(token);
                console.log(decodedToken);
                array = decodedToken.split(" ");
                console.log(array);
                groupId = array[0];
                console.log(groupId);
                groupName = array[1];
                userId = array[2];
                console.log(userId, groupName, groupId);
                

                async function login() {
                  const options = {
                    method: "POST",
                    url: `${paths.api}/authenticate`,
                    Headers: {
                      "Content-Type": "aplication/json",
                    },
                    data: {
                      userName: formulario.userName,
                      password: formulario.password,
                    },
                  };

                  await axios.request(options).then(function (res) {
                    console.log(res.data);

                    localStorage.setItem(
                      "UserName",
                      JSON.stringify(formulario.userName)
                    );
                    localStorage.setItem(
                      "token",
                      JSON.stringify(res.data.token)
                    );
                    localStorage.setItem("userId", JSON.stringify(res.data.id));
                    localStorage.setItem(
                      "groupId",
                      JSON.stringify(parseInt(groupId))
                    );

                    console.log("estado : ", res.status);
                    console.log("token : ", res.data.token);
                    console.log("Id usuario : ", res.data.id);
                    console.log("data group id xd" + res.data.groupId);

                    const putGroup = async () => {
                      let dataRequest = {
                        method: "put",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${res.data.token}`,
                        },
                      };

                      axios
                        .put(
                          `${paths.api}/api/groups/${groupId}/users/${res.data.id}`,
                          groupId,
                          dataRequest
                        )
                        .then((res) => {
                          if (res.status === 200) {
                            Swal.fire({
                              icon: "success",
                              title: "Genial...",
                              text: "Registro Exitoso!",
                            });
                            window.location.href = "/mygroup";
                          }
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    };
                    putGroup();
                  });
                }
                login();
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Genial...",
                  text: "Registro Exitoso!",
                });
                window.location.href = "/";
              }

              setFormulario({
                userName: "",
                firstName: "",
                lastName: "",
                email: "",
                role: "ROLE_USER",
                typeDocument: "",
                documentId: "",
                bornDate: "",
                gender: "",
                phone: "",
                password: "",
              });
            }
          })
          .catch(function (response) {
            if (response.status !== 200) {
              Swal.fire({
                icon: "error",
                title:
                  "No es posible conectarse con el servidor en este momento",
                text: "Error!",
              });
            }
          });
      };
      insertarFour();
    } else {
      Swal.fire({
        icon: "error",
        title: "Usted no es mayor de edad",
        text: "Error!",
      });
    }
  };

  return (
    <div className="container-signin-body">
      <h2 className="signin-title">Registro</h2>
      <form
        className="container-signin-form"
        id="signinForm"
        onSubmit={handleSubmit}
        action="/login"
      >
        <input
          required
          type="text"
          name="userName"
          placeholder="Nombre de usuario"
          onChange={handleChange}
          value={userName}
          className="UserName"
        />
        <input
          required
          type="text"
          name="firstName"
          placeholder="Nombre"
          onChange={handleChange}
          value={firstName}
          className="Name"
        />
        <input
          required
          type="text"
          name="lastName"
          placeholder="Apellido"
          onChange={handleChange}
          value={lastName}
          className="LastName"
        />
        <input
          required
          type="email"
          placeholder="Correo"
          name="email"
          value={email}
          onChange={handleChange}
          className="email"
        />
        <input
          required
          type="text"
          placeholder="Numero De Documento"
          name="documentId"
          value={documentId}
          onChange={handleChange}
          className="documentId"
        />
        <select
          required
          name="typeDocument"
          className="documentType"
          onChange={handleChange}
        >
          <option value="" disabled selected>
            Tipo de documento
          </option>
          <option value="INE">INE</option>
          <option value="Pasaporte">Pasaporte</option>
        </select>

        <div className="container-bornDate">
          <label className="signin-label" for="bornDate">
            Fecha de nacimiento
          </label>
          <input
            type="date"
            id="bornDate"
            name="bornDate"
            label="fecha de nacimiento"
            value={bornDate}
            onChange={handleChange}
            className="bornDate"
          />
        </div>
        <select name="gender" className="genre" onChange={handleChange}>
          <option value="" disabled selected>
            Genero
          </option>
          <option value="Femenino">Femenino</option>
          <option value="Masculino">Masculino</option>
          <option value="Prefiero no decir">Prefiero no decir</option>
        </select>
        <input
          required
          type="number"
          min={10}
          placeholder="Telefono"
          name="phone"
          value={phone}
          onChange={handleChange}
          className="phone"
        />
        <input
          required
          type="password"
          placeholder="Contraseña"
          name="password"
          value={password}
          onChange={handleChange}
          className="password"
        />
      </form>

      <button form="signinForm" className="signin-submit" type="submit">
        Registrarse
      </button>
    </div>
  );
}

export default SignInForm;
