import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Swal from "sweetalert2";
import { paths } from "../../Paths";

//Datos almacenados en el localStorage
let token = JSON.parse(localStorage.getItem("token"));
let idGroup = JSON.parse(localStorage.getItem("groupId"));
console.log(token);

class DepositClass extends Component {
  //constructor
  constructor(props) {
    super(props);
    //informacion privada que tiene el componente que puede actualizarse
    this.state = {
      amountDeposited: 0,
    };
  }

  //FUNCIONES
  updateAmount = (monto) => {
    console.log(this.state.amountDeposited);
    this.setState((prevState) => ({
      amountDeposited: prevState.amountDeposited + monto,
    }));
    console.log(monto);
    console.log(this.state.amountDeposited);
    return this.state.amountDeposited;
  };

  //actualizar deposito
  updateDeposit = async (monto, memberId) => {
    let data = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${paths.api}/api/users/${memberId}/deposits`, data)
      .then((response) => {
        this.setState(response.data);
        console.log(response.data);

        console.log("monto", this.state.amountDeposited);
        console.log("montores", response.data.amountDeposited);
        console.log(typeof monto);
        let newAmount = parseInt(
          parseInt(response.data.amountDeposited) + parseInt(monto)
        );
        console.log(newAmount);
        const dataUpdate = {
          id: memberId,
          amountDeposited: newAmount.toString(),
        };
        console.log(dataUpdate);

        console.log(this.state.amountDeposited);
        let dataRequest = {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .put(
            `${paths.api}/api/groups/${idGroup}/users/${memberId}/deposits`,
            dataUpdate,
            dataRequest
          )
          .then((response) => this.setState(response.data));

        console.log(this.state.amountDeposited);
        return this.state.amountDeposited;
      })
      .catch(function (error) {
        console.log(error.data);
        Swal.fire({
          icon: "error",
          title: "error",
          text: "Servidor no esta conectado",
          showConfirmButton: true,
        });

        return;
      }, []);
  };

  //actualizar deposito
  LoanShellOut = async (monto, memberId, groupId) => {
    let data = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${paths.api}/api/users/${memberId}/deposits`, data)
      .then((response) => {
        this.setState(response.data);
        console.log(response.data);

        console.log("monto", this.state.amountDeposited);
        console.log("montores", response.data.amountDeposited);
        console.log(typeof monto);
        let newAmount = parseInt(
          parseInt(response.data.amountDeposited) - parseInt(monto)
        );
        console.log(newAmount);
        if (parseInt(response.data.amountDeposited) < parseInt(monto)) {
          Swal.fire({
            icon: "warning",
            title: "El monto excede la cantidad del deposito",
            text: `Saldo maximo a retirar ${response.data.amountDeposited}`,
            showConfirmButton: true,
          });
        } else {
          const dataUpdate = {
            id: memberId,
            amountDeposited: newAmount.toString(),
          };
          console.log(dataUpdate);

          console.log(this.state.amountDeposited);
          let dataRequest = {
            method: "put",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios
            .put(
              `${paths.api}/api/groups/${groupId}/users/${memberId}/deposits`,
              dataUpdate,
              dataRequest
            )
            .then(
              (response) => this.setState(response.data),
              Swal.fire({
                title: "Operación exitosa",
                text: "Monto actualizado",
                icon: "success",
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                  //<Link to={`adminloans/${groupId}/${amountstr}/${memberId}`}></Link>
                }
              })
            );

          console.log(this.state.amountDeposited);
          return this.state.amountDeposited;
        }
      })
      .catch(function (error) {
        console.log(error.data);
        Swal.fire({
          icon: "error",
          title: "error",
          text: "Servidor no esta conectado",
          showConfirmButton: true,
        });

        return;
      }, []);
  };

  //Nueva implentacion desembolso credito
  LoanShellOutAdmin = async (consecutive, idMember) => {
    const options = {
      method: "POST",
      url: `${paths.api}/api/consecutive/${consecutive}/user/${idMember}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        data: {},
      },
    };

    try {
      axios
        .request(options)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (err) {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }

    /* await axios
    .request(options)
    .then(function (response) {
      console.log(response.data);
      localStorage.setItem("tokenToApprove", response.data.token);

      setLogin(true);
    })
    .catch(function (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Credenciales",
        text: "Las credenciales ingresadas son incorrectas",
        showConfirmButton: true,
      });
    });*/
  };

  // render return donde se ubica el html
  render() {
    return <h6 class="stripe-form-cash-text"></h6>;
  }
}

//contenido que se le puede enviar desde un componente de orden superior
DepositClass.propTypes = {
  amountDeposited: PropTypes.number,
};

export default DepositClass;
