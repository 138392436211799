import "../Header/Header.css";
import Logo from "../../img/LOGO.png";
import React, { useState } from "react";
import {
  Container,
  LogoContainer,
  Wrapper,
  Menu,
  MenuItem,
  MenuItemLink,
  MobileIcon,
} from "../Header/Header.elements.js";

import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { GetInfoGroup } from "../../Functions/GetInfoGroup";

const NavbarAdmin = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  let groupId = JSON.parse(localStorage.getItem("groupId"));
  const token = JSON.parse(localStorage.getItem("token"));
  const idUser = JSON.parse(localStorage.getItem("userId"));

  //funcion para obtener la informacion del grupo
  let GroupInfo = GetInfoGroup(groupId, token);
  console.log(GroupInfo.leaderId);
  console.log(idUser);

  return (
    <Container>
      <Wrapper>
        <IconContext.Provider value={{ style: { fontSize: "2em" } }}>
          <LogoContainer>
            <img className="header-logo" alt="logo" src={Logo} />
            <h1 className="header-name">Cochinito App</h1>
          </LogoContainer>

          <MobileIcon onClick={() => setShowMobileMenu(!showMobileMenu)}>
            {showMobileMenu ? <FaTimes /> : <FaBars />}
          </MobileIcon>

          <Menu open={showMobileMenu}>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/" className="link-menu">
                    Inicio
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/admin" className="link-menu">
                    Información
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/admindetails" className="link-menu">
                    Información Dev
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
          </Menu>
        </IconContext.Provider>
      </Wrapper>
    </Container>
  );
};

export default NavbarAdmin;
