import React, { useEffect, useState } from "react";
import GetTotalGroup from "../../../Functions/GetTotalGroup";
import "../Admin.css";


function AdminAmountGroup() {
  const [amountGroups, setAmountGroups] = useState();
  console.log(amountGroups);

  async function Controller() {
    GetTotalGroup()
      .then((response) => {
        console.log(response);
        setAmountGroups(response.data);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);

  return (
    <div className="container-admin-table table-size">
      <table className="admin-table">
       <thead>
          <tr>
            <th>Id</th>
            <th>Deposito</th>
          </tr>
        </thead>
        <tbody>
       {amountGroups && amountGroups.map((amountGroups, i) => (
            <tr  key={i}>
              <td className="table-border-cell">{amountGroups[0]}</td>
              <td className="table-border-cell">{amountGroups[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
 
}
export default AdminAmountGroup;

