import axios from 'axios';
import { paths } from '../Paths';

const GetGroups = async () => {

    let token = JSON.parse(localStorage.getItem('token'));

    console.log(token)

        let dataRequest = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        
       return await axios.get(`${paths.api}/api/groups`, dataRequest);
}

export default GetGroups;
