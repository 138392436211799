

function GetLeaderName(members , leaderId){

    console.log(members);

    for(let i=0 ; i < members.length ; i++ ){
       if(members[i].id === leaderId){
            return members[i].userName
       }
    }
}

export {GetLeaderName}