import axios from "axios";
import React, { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import GetUserDeposit from "../../Functions/GetUserDeposit";
import "./LoanForm.css";
import { paths } from "../../Paths";

function LoanForm() {
  let operationId;

  //Datos almacenados en el localStorage
  const token = JSON.parse(localStorage.getItem("token"));
  const idUser = JSON.parse(localStorage.getItem("userId"));
  let idGroup = JSON.parse(localStorage.getItem("groupId"));

  //llamado a funcion de monto de ahorro del usuario
  let userDeposit = GetUserDeposit(idUser, token);
  console.log(userDeposit.amountDeposited);

  //monto maximo del prestamo 120%
  let userDepositLoan = parseInt(userDeposit.amountDeposited * 1.2);
  console.log(userDepositLoan);

  //hooks
  const [transaccion, setTransaccion] = useState([]);
  const [operation, setOperation] = useState([]);
  const [cuotas, setCuotas] = useState({
    number: "",
  });
  const [loan, setLoan] = useState({
    amount: "",
  });
  const [frequency, setFrequency] = useState({
    frequency:"",
  });

  //manejadores para capturar datos
  const handleChange = (e) => {
    setLoan({
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeFrequency = (e) => {
    setFrequency({
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeCuotas = (e) => {
    setCuotas({
      [e.target.name]: e.target.value,
    });
  };

  //verificar que el monto solicitado no sea mayor que el que esta en deposito
  if (parseInt(loan.amount) > userDepositLoan) {
    Swal.fire({
      icon: "warning",
      title: "Error",
      text: "El monto solicitado supera el maximo de su cupo",
    });

    setLoan({
      amount: "",
    });
  }

  // insertar una operación
  console.log(idGroup);
  console.log(loan);
  console.log(loan.amount);
  console.log(operation);
  console.log(transaccion);
  console.log(cuotas);

  useEffect(() => {
    const insertOpera = async () => {
      console.log("operacion");
      const fecha = new Date();
      const hoy = fecha.toLocaleDateString();
      const transactionData = localStorage.getItem("transactionData");
      console.log(transactionData);
      const options = {
        method: "POST",
        url: `${paths.api}/api/groups/${idGroup}/users/${idUser}/transactions/${transactionData}/operations`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          consecutive: `${cuotas.cuotas}`,
          transactionDate: hoy,
          value: loan.amount,
          paymentFrequency: frequency.frequency,
        },
      };

      await axios
        .request(options)
        .then(function (response) {
          console.log(response);
          setOperation(response.data.id);
          console.log(response.data.id);
          localStorage.setItem(
            "operationRecentId",
            JSON.stringify(response.data.id)
          );
          console.log(localStorage.getItem("operationRecentId"));

          sendMail();
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    insertOpera();

    const sendMail = async () => {
      operationId = localStorage.getItem("operationRecentId");
      const transactionId = localStorage.getItem("transactionData");
      console.log("La operación es de " + operationId);
      console.log("la transacción es de " + transactionId);
      const options = {
        method: "POST",
        url: `${paths.api}/api/groups/${idGroup}/transactions/${transactionId}/operations/${operationId}/send_email`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          groupId: idGroup,
          operationId: operationId,
        },
      };
      await axios
        .request(options)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response);

            Swal.fire({
              icon: "success",
              title: "Genial...",
              text: "Solicitud enviada",
            });

            setLoan({
              amount: "",
            });
          }
        })
        .catch(function (response) {
          if (response.status !== 200) {
            Swal.fire({
              icon: "error",
              title: "No es posible conectarse con el servidor en este momento",
              text: "Error!",
            });
          }
        });
    };
  }, [transaccion]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const insertransac = async () => {
      console.log(idGroup);
      console.log(idUser);
      console.log(token);
      const options = {
        method: "POST",
        url: `${paths.api}/api/groups/${idGroup}/users/${idUser}/transactions/`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          transactionName: "loan",
          isTypeOperation: true,
        },
      };
      await axios
        .request(options)
        .then(function (response) {
          console.log(response);
          setTransaccion(response.data.id);
          localStorage.setItem("transactionData", response.data.id);
          console.log(localStorage.getItem("transactionData"));
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "No es posible conectarse con el servidor en este momento",
            text: "Error!",
          });
        });
      //setOperation()
    };
    insertransac();
  };

  return (
    <div className="container-loanForm">
      <h2 className="title-loan">Préstamos</h2>
      <form className="container-loan-form" onSubmit={handleSubmit}>
        <section class="loan-form-info">
          Solicite un prestamo a su grupo de ahorro, ingrese el monto y espere a
          que su equipo autorice el retiro del dinero.
        </section>
        <section class="loan-form-cash">
          <h6 class="stripe-form-cash-text">
            Monto maximo de prestamo :{" "}
            <span className="deposit"> $ {userDepositLoan}</span>
          </h6>
          <input
            class="loan-form-cash-input"
            required
            type="number"
            placeholder="Ingrese el monto"
            name="amount"
            onChange={handleChange}
            value={loan.amount}
            min={0}
          />
        </section>

        <section class="loan-form-cash">
          <input
            class="loan-form-cash-input"
            required
            type="number"
            placeholder="Numero de Pagos"
            name="cuotas"
            onChange={handleChangeCuotas}
            value={cuotas.number}
          />
          <select
            required
            name="frequency"
            className="loan-form-cash-input"
            onChange={handleChangeFrequency}
          >
            <option value="" disabled selected>
              Periodos de Pagos
            </option>
            <option value="Semanal">Semanal</option>
            <option value="Quincenal">Quincenal</option>
            <option value="Mensual">Mensual</option>
          </select>
        </section>

        <button className="loan-button"> Solicitar Autorización </button>
      </form>
    </div>
  );
}

export default LoanForm;
