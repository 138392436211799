import axios from 'axios';
import React, { useState } from 'react';
import Swal from "sweetalert2";
import './InviteForm.css';
import { paths } from '../../Paths';

function InviteForm() {

    //hooks
    const [mail, setMail] = useState('');

    //informacion del localStorage
    let groupId = JSON.parse(localStorage.getItem("groupId"));
    console.log(groupId)
    const token = JSON.parse(localStorage.getItem('token'));
    console.log(token);

    function handleChange(e) {
        setMail(e.target.value)
    }
    console.log(mail);

    
    const handleSubmit = async (e) => {
        e.preventDefault();

        var options = {
            method: 'POST',
            url: `${paths.api}/api/groups/${groupId}/send_email`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            data: {email: mail}
          };
          
          axios.request(options).then(function (response) {
            console.log(response);
            Swal.fire({
                icon: 'success',
                title: 'Genial...',
                text: 'Invitación enviada!',
                showConfirmButton: true,
                //timer: 1500
            })
          }).catch(function (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error!',
            })
          });
    }


    return (
        <div className='container-invite-form'>
            <form className='invite-form' onSubmit={handleSubmit}>

                <section class='invite-form-title' >
                    Invitar un nuevo integrante al grupo
                </section>
                <section class='invite-form-cash' >
                    <input class='invite-form-cash-input' required
                        type='email'
                        placeholder='Ingrese el e-mail'
                        name='email'
                        label="email"
                        onChange={handleChange}
                        value= {mail}
                    />
                    <h6 class='invite-form-cash-text'> </h6>
                </section>
                <button className='invite-button' type='submit'>Enviar Invitación</button>
            </form>
        </div>

    )
}

export default InviteForm;