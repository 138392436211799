import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import LoginForm from "../../components/Login/LoginForm";
import VectorLineas from "../../img/Index/VectorLineas.svg";
import "./Login.css";

function Login() {
  return (
    <div className="main-container">
      <Header />
      <section className="container-loginUser-body">
        <article className="container-login-info">
          <p className="login-info-text">
            En cochinito app podras unirte a un grupo de ahorro que se adapte a
            tus necesidades y acceder a prestamos gracias a la aprobación de tu
            grupo de ahorro.
          </p>
          <iframe
              src="https://docs.google.com/presentation/d/e/2PACX-1vQxRXbVo2wv4BjtFtfog5iik6qM9hrZaZJ2TgF-dqTO0kgg2RWxCxmD1dS0CEb_DyczGhG4tNFl0ETX/embed?start=true&loop=true&delayms=5000"
              frameborder="0"
              width="100%"
              height="250px"
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
            ></iframe>
        </article>
        <article className="container-login-vectorline">
          <img
            alt="vector-lines"
            className="login-vectorLines"
            src={VectorLineas}
          />
        </article>
        <article className="container-login-forms">
          <h2 className="login-info-title">Bienvenido</h2>
          <LoginForm />
        </article>
      </section>
      <Footer />
    </div>
  );
}

export default Login;
