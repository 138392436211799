import '../Header/Header.css'
import Logo from "../../img/LOGO.png";
import React, { useState } from "react";
import {
  Container,
  LogoContainer,
  Wrapper,
  Menu,
  MenuItem,
  MenuItemLink,
  MobileIcon,
} from "../Header/Header.elements.js";

import {
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { IconContext } from "react-icons";
import { Link } from 'react-router-dom';
import { GetInfoGroup } from '../../Functions/GetInfoGroup';


const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  let groupId = JSON.parse(localStorage.getItem("groupId"));
  const token = JSON.parse(localStorage.getItem('token'));
  const idUser = JSON.parse(localStorage.getItem('userId'));

  //funcion para obtener la informacion del grupo
  let GroupInfo = GetInfoGroup(groupId, token);
  console.log(GroupInfo.leaderId);
  console.log(idUser);

  function addButtonInvite() {
    if (GroupInfo.leaderId === idUser) {
      return (
        <MenuItem>
          <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <div>
              <Link to="/invite"  className='link-menu'>

                Invitar 
              </Link>
            </div>
          </MenuItemLink>
        </MenuItem>
      )
    }
  }
  

  return (
    <Container>
      <Wrapper>
        <IconContext.Provider value={{ style: { fontSize: "2em" } }}>
          <LogoContainer>
            <img className='header-logo' alt='logo' src={Logo} />
            <h1 className='header-name'>Cochinito App</h1>

          </LogoContainer>

          <MobileIcon onClick={() => setShowMobileMenu(!showMobileMenu)}>
            {showMobileMenu ? <FaTimes /> : <FaBars />}
          </MobileIcon>

          <Menu open={showMobileMenu}>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/" className='link-menu'>

                    Inicio
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/mygroup" className='link-menu'>

                    Mi grupo
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/stripe" className='link-menu'>

                    Ahorros
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <Link to="/loan" className='link-menu'>

                    Préstamos
                  </Link>
                </div>
              </MenuItemLink>
            </MenuItem>
            {addButtonInvite()}
          </Menu>
        </IconContext.Provider>
      </Wrapper>
    </Container>
  );
}

export default Navbar;