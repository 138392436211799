import React from 'react';
import CreditPaymentsForm from '../../components/CreditPayments/CreditPaymentsForm';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';

function CreditPaymentsPage() {
  return (
    <div className='main-container'>
        <Navbar />
        <CreditPaymentsForm />
        <Footer/>
    </div>
  )
}

export default CreditPaymentsPage