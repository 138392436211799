import React from 'react';
import Footer from "../../components/Footer/Footer";
import InviteForm from "../../components/Invite/InviteForm";
import Navbar from "../../components/Navbar/Navbar";
import UserSession from "../../components/UserSession/UserSession";

function Invite() {
    return (
        <div className='main-container'>
            <UserSession />
            <Navbar />
            <InviteForm />
            <Footer />
        </div>
    )
}

export default Invite;