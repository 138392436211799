import axios from 'axios';
import { useState ,useEffect} from 'react';
import { paths } from '../Paths';



function GetMembersGroup(idGroup,token) {
    
    const getData = async () => {

        let dataRequest = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        
        //const response = axios.get(`https://conchinito-4vozlpb6ma-uc.a.run.app/api/groups/${idGroup}/users`, dataRequest);
        const response = axios.get(`${paths.api}/api/groups/${idGroup}/users`, dataRequest);

        return response;
    }

    const [members, setMembers] = useState([]);

    useEffect(() => {
        getData().then((response) => {
            console.log(response);

            setMembers(response.data.content);
    
        })
    }, [])

    return members;
    
}

export { GetMembersGroup };
