import React from 'react';
import ApproveViewForm from "../../components/ApproveView/ApproveViewForm";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

function Approve() {

    return (
        <div className='main-container'>
            <Navbar />
            <ApproveViewForm />
            <Footer />
        </div>
    );
}

export default Approve;