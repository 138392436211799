import React from 'react';
import NavbarAdmin from '../../components/NavbarAdmin/NavbarAdmin';
import Footer from '../../components/Footer/Footer';
import AdminShellOutMoney from '../../components/Admin/AdminShellOutMoney/AdminShellOutMoney';


const AdminLoans = () => {
    return (
        <div className='main-container'>
            <NavbarAdmin />
            <AdminShellOutMoney />
            <Footer />
        </div>
    );
}

export default AdminLoans;
