import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import * as React from "react";
import { useEffect, useState } from "react";
import GetAmountGroups from "../../../Functions/GetAmountGroups";
import "../Admin.css";

const AdminAmountGroupsDev = () => {
  const columns = [
    {
      field: 'id',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      resizable: false,
      headerAlign: 'center',
    },
    {
      field: 'amountDeposited',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    },
  ];

  const [rows, setRows] = useState({});

  async function Controller() {
    GetAmountGroups()
      .then((response) => {
        console.log(response.data)
        setRows(response.data.content);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);



  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="table-mui ">
      {rows && <DataGrid
        {...rows}
        title={"Lista de Usuários"}
        rows={rows}
        slots={{
          toolbar: CustomToolbar,
        }}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }, { field: 'amountDeposited', sort: 'desc' }],
          },
        }}
        checkboxSelection
        autoHeight
      />}
    </div>
  );
};

export default AdminAmountGroupsDev;
