import axios from 'axios';
import { useState, useEffect } from 'react';
import { paths } from '../Paths';


function GetUserDeposit(idUser, token) {
    console.log(idUser);

    const getData = async () => {
        let dataRequest = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        console.log(idUser);
        const response = axios.get(`${paths.api}/api/users/${idUser}/deposits`,dataRequest);
        console.log(response.data)
        return response;
    }

    const [userDeposit, setUserDeposit] = useState([]);

    useEffect(() => {
        getData().then((response) => {
            setUserDeposit(response.data);
            console.log(response);

            localStorage.setItem('deposit', JSON.stringify(parseInt(response.data.amountDeposited)))

            console.log(response.status);
            console.log(response.data);


        }).catch(function (error) {
            console.log(error.data);
        })
    },[])

    console.log(userDeposit.amountDeposited);

    return userDeposit;

    
}

export default GetUserDeposit;