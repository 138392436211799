import React from "react";
import AdminAmountGroupsDev from "../../components/Admin/AdminAmountGroups/AdminAmountGroupsDev";
import AdminGroup from "../../components/Admin/AdminGroup/AdminGroup";
import AdminLoanByUser from "../../components/Admin/AdminLoans/AdminLoansByUser";
import AdminLoansDev from "../../components/Admin/AdminLoans/AdminLoansDev";
import AdminOperations from "../../components/Admin/AdminOperations/AdminOperations";
import Footer from "../../components/Footer/Footer";
import NavbarAdmin from "../../components/NavbarAdmin/NavbarAdmin";
import './adminstructure.css';

const AdminDetails = () => {
  return (
    <div className="main-container ">
      <NavbarAdmin />

      <section className="container-grid">
        
          <h2 className="admin-subtitle">Depositos por grupo</h2>
          <AdminOperations />
        

          <h2 className="admin-subtitle">Grupos</h2>
          <AdminGroup />

        <section className="container-groups-loans">
          <article className="adminloans">
            <h2 className="admin-subtitle">Preestamos - id, loanPayable</h2>
            <AdminLoansDev />
          </article>
          <article className="adminloans">
            <h2 className="admin-subtitle">amountGroups - id,amountDeposited</h2>
            <AdminAmountGroupsDev />
          </article>
        </section>

      </section>
      <Footer />
    </div>
  );
};

export default AdminDetails;
