import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { paths } from "../../Paths";
import "./StripeForm.css";

//clave publica stripe -> carga stripe
const stripePromise = loadStripe(
  "pk_test_51L2d1TDRt7jlx87wxGsSzQnFkhj7YR58IO59XshMQ2q1W8204ZlmmiAYouCBhLjcu7UddzJXPl9usYWGeIcAtPmp00j4JE4LUl"
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const getData = async () => {
    let dataRequest = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = axios.get(`${paths.api}/api/groups`, dataRequest);
    return response;
  };

  const token = JSON.parse(localStorage.getItem("token"));
  const idUser = JSON.parse(localStorage.getItem("userId"));
  const [grupoActual, setGrupoActual] = useState([]);
  const [grupo, setGrupo] = useState();
  const [transaccion, setTransaccion] = useState([]);
  const [monto, setMonto] = useState({
    amount: "",
  });
  const montoDolar = parseInt(monto.amount) * 0.051;
  const montoCentavo = montoDolar * 100;

  const handleChange = (e) => {
    setMonto({
      ...monto,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getData().then((response) => {
      setGrupo(response.data);

      let idGroup;
      console.log(response);
      const result = response.data;
      const grupo = result.content;
      console.log(grupo[0].leaderId);

      for (let i = 0; i < grupo.length; i++) {
        if (grupo[i].leaderId === idUser) {
          idGroup = grupo[i].id;
          console.log(idGroup);
        }
      }
      setGrupoActual(idGroup);

      console.log(grupoActual);
    });
  }, []);

  console.log(grupoActual);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      console.log(paymentMethod);
      const { id } = paymentMethod;

      const { data } = await axios.post("http://localhost:5000/api/checkout", {
        id,
        amount: parseInt(montoCentavo),
      });
      console.log(data);
      Swal.fire({
        icon: "success",
        title: "genial",
        text: "Transaccion exitosa",
        showConfirmButton: true,
      });

      insertransac();
    }

    if (error) {
      Swal.fire({
        icon: "error",
        title: "error",
        text: "Error!",
        showConfirmButton: true,
      });
    }

    const insertransac = async () => {
      console.log(grupoActual);
      console.log(idUser);
      console.log(token);
      const options = {
        method: "POST",
        url: `${paths.api}/api/groups/${grupoActual}/users/${idUser}/transactions/`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          transactionName: "consign",
          isTypeOperation: true,
        },
      };
      await axios
        .request(options)
        .then(function (response) {
          console.log(response);
          setTransaccion(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
  };
  useEffect(() => {
    const insertOpera = async () => {
      const fecha = new Date();
      const hoy = fecha.toLocaleDateString();
      console.log(transaccion.id);
      const options = {
        method: "POST",
        url: `${paths.api}/api/groups/${grupoActual}/users/${idUser}/transactions/${transaccion.id}/operations`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          consecutive: idUser,
          documentId: "",
          value: monto.amount,
          transactionDate: hoy,
        },
      };
      await axios
        .request(options)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    insertOpera();
  }, [transaccion]);

  return (
    <form className="container-stripe-form" onSubmit={handleSubmit}>
      <section class="stripe-form-card">
        <CardElement />
      </section>
      <section class="stripe-form-cash">
        <input
          class="stripe-form-cash-input"
          required
          type="number"
          min={10}
          placeholder="Ingrese el monto"
          name="amount"
          label="Monto"
          onChange={handleChange}
          value={monto.amount}
        />
        <h6 class="stripe-form-cash-text">*monto minimo 10 pesos mexicanos</h6>
      </section>
      <button className="stripe-button" type="submit" disabled={!stripe}>
        Enviar
      </button>
    </form>
  );
};

function StripeForm() {
  return (
    <section className="ab">
      <div className="container-sub-title-stripe">
        <h2 className="sub-title-stripe">Ahorro Con Tarjeta</h2>
      </div>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </section>
  );
}

export default StripeForm;
