import axios from 'axios';
import { useState, useEffect } from 'react';
import { paths } from '../Paths';



function GetOperation(url, token) {
    let total = 0;
    console.log(url);

    const getData = async () => {
        let dataRequest = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }

        console.log(url);
        const response = axios.get(url, dataRequest);

        return response;
    }

    const [operation, setOperation] = useState([]);

    useEffect(() => {
        getData().then((response) => {
            setOperation(response.data.content);



            for (let i = 0; i < operation.length; i++) {

                let number = parseInt(operation[i].value, 10)
                total = total + number;
                console.log(total);
            }
            console.log(response);

        }).catch(function (error) {
            console.log(error);
        })
    }, [])

    console.log(operation);
    console.log(operation.length);


    return total;

}

function GetTotalGroup(idGroup, token) {
console.log(idGroup);
    let url = `${paths.api}/api/groups/${idGroup}/deposits-sum`;

    const getData = async () => {
        let dataRequest = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }

        console.log(url);
        const response = axios.get(url, dataRequest);
        
        return response;
    }

    const [operation, setOperation] = useState([]);

    useEffect(() => {
        getData().then((response) => {
            console.log(response);
            setOperation(response.data.totalAmountDeposited);
           console.log(operation)

        }).catch(function (error) {
            console.log(error);
        })
    }, [])
    
    return operation;
}

function GetTotalUser(idUser, token) {
    let url = `${paths.api}/api/users/${idUser}/operations`;
    let operationsUser = GetOperation(url, token);
    console.log(operationsUser);

    return operationsUser;
}

export { GetTotalGroup, GetTotalUser };