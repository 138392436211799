import React, { useEffect, useState } from "react";
import GetGroups from "../../../Functions/GetGroups";
import "../Admin.css";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";



const AdminGroup = () => {
  const columns = [
    {
      field: 'id',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      resizable: false,
      headerAlign: 'center',
    },
    {
      field: 'groupName',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'leaderId',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'approvedLoanInterest',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'finishDate',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'frequency',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    }, 
    {
      field: 'minimumAmount',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    },
  ];

  const [rows, setRows] = useState({});

  async function Controller() {
    GetGroups()
      .then((response) => {
        setRows(response.data.content);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);



  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="table-groups ">
      {rows && <DataGrid
        {...rows}
        title={"Lista de Usuários"}
        rows={rows}
        slots={{
          toolbar: CustomToolbar,
        }}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'asc' }, { field: 'amountDeposited', sort: 'asc' }],
          },
        }}
        checkboxSelection
        autoHeight
      />}
    </div>
  );

 /* const [groups, setGroups] = useState();
  console.log(groups);

  async function Controller() {
    GetGroups()
      .then((response) => {
        console.log(response);
        setGroups(response.data.content);
      })
      .catch(function error(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    Controller();
  }, []);

  return (
    <div className="container-admin-table">
      <table className="admin-table">
       <thead>
          <tr>
            <th>Nombre del grupo</th>
            <th>Lider Id</th>
            <th>Intereses de prestamo</th>
            <th>Fecha de cierre</th>
            <th>Frecuencia Depositos</th>
            <th>Monto minimo</th>
          </tr>
        </thead>
        <tbody>
       {groups && groups.map((group, i) => (
            <tr  key={i}>
              <td className="table-border-cell">{group.groupName}</td>
              <td className="table-border-cell">{group.leaderId}</td>
              <td className="table-border-cell">{group.approvedLoanInterest} %</td>
              <td className="table-border-cell">{group.finishDate} meses</td>
              <td className="table-border-cell">{group.frequency}</td>
              <td className="table-border-cell">{group.minimumAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );*/
};

export default AdminGroup;
