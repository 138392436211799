import axios from 'axios';
import { paths } from '../Paths';

const GetOperations = async () => {
    
    let token = JSON.parse(localStorage.getItem('token'));

    console.log(token)

        let dataRequest = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        
       return await axios.get(`${paths.api}/api/operations`, dataRequest);
}

export default GetOperations;
