import './Config.css'
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { GetDataUser } from '../../Functions/GetDataUser';
import { paths } from '../../Paths';


const ConfigForm = () => {

    //Datos almacenados en el localStorage 
    const token = JSON.parse(localStorage.getItem('token'));
    const idUser = JSON.parse(localStorage.getItem('userId'));

    //state para validar que los datos del formulario esten llenos   
    const [error, setError] = useState(false)
    console.log(error)

    //funcion para extraer los datos del usuario
    let dataPrev = GetDataUser(idUser, token);
    console.log(dataPrev.userName);
    console.log(typeof (dataPrev.userName));
    let userUpdate = dataPrev.userName;
    console.log(userUpdate);

    //state abrir formulario de actualizar datos
    const [open, setOpen] = useState(false);
    function openForm() {
        setOpen(!open);
    }

    function UpdateForm() {
        console.log('entro a actualizar')

        //state del formulario
        const [formulario, setFormulario] = useState({
            userName: dataPrev.userName,
            firstName: dataPrev.firstName,
            lastName: dataPrev.lastName,
            email: dataPrev.email,
            typeDocument: dataPrev.typeDocument,
            documentId: dataPrev.documentId,
            bornDate: dataPrev.bornDate,
            gender: dataPrev.gender,
            phone: dataPrev.phone,
            password: ''
        })


        //handleChange para llenar el formulario
        const handleChange = (e) => {
            setFormulario({
                ...formulario,
                [e.target.name]: e.target.value,

            });

            console.log(formulario.userName);
            console.log(formulario.typeDocument);
            console.log(formulario.gender);
        };

        //desfragmetamos el state formulario  
        const { userName, firstName, lastName, email, password, typeDocument, documentId, bornDate, gender, phone } = formulario
        console.log(formulario)

        //handleSubmit para enviar el formulario
        const handleSubmit = (e) => {
            console.log("entro a enviar");

            e.preventDefault();
            if (userName.trim() === "" || firstName.trim() === "" || lastName.trim() === "" || email.trim() === "" || documentId.trim() === "" || phone.trim() === "" || password.trim() === "") {
                setError(true)


                Swal.fire({
                    icon: 'error',
                    title: 'Complete todos los campos',
                    text: 'Error!',
                })

                return;
            }

            setError(false)

            const insertarFour = async () => {
                const dataUpdate = formulario;
                let dataRequest = {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                }

                try {
                    await axios.put(`${paths.api}/api/users/${idUser}`, dataUpdate, dataRequest)
                        .then(function (response) {
                            setFormulario(response.data)

                            Swal.fire({
                                icon: 'success',
                                title: 'Actualización realizada con exito',
                                text: 'Vuelva a ingresar para guardar los cambios',
                                confirmButtonText: 'Ok',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href = '/';
                                }
                            })
                        }
                        );

                } catch (error) {
                    console.log(error.response.data)

                    Swal.fire({
                        icon: 'error',
                        title: 'Error en la actualización',
                        text: 'Error!',
                    })
                }

                console.log(formulario)
            }
            insertarFour();
        }

        return (
            <div className='container-update-data-form'>
                <form className='update-data-form' id="update-data-Form" onSubmit={handleSubmit} action='/login'>
                    <p className='label-form-color'>User Name</p>
                    <input
                        required
                        type="text"
                        name='userName'
                        placeholder="Nombre de usuario"
                        onChange={handleChange}
                        value={userName}
                        className='inputUpdate UserName'
                    />
                    <p className='label-form-color'>Nombre</p>
                    <input
                        required
                        type="text"
                        name='firstName'
                        placeholder="Nombre"
                        onChange={handleChange}
                        value={formulario.firstName}
                        className='inputUpdate Name'
                    />
                    <p className='label-form-color'>Apellido</p>
                    <input
                        required
                        type="text"
                        name='lastName'
                        placeholder="Apellido"
                        onChange={handleChange}
                        value={formulario.lastName}
                        className='inputUpdate LastName'
                    />
                    <p className='label-form-color'>E-mail</p>
                    <input
                        required
                        type='email'
                        placeholder='Correo'
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className='inputUpdate email'
                    />
                    <p className='label-form-color'>Tipo de documento</p>
                    <select required name="typeDocument" className='inputUpdate documentType' onChange={handleChange} >
                        <option value='' disabled selected>Tipo de documento</option>
                        <option defaultValue={dataPrev.typeDocument} selected>{dataPrev.typeDocument}</option>
                        <option value='INE'>INE</option>
                        <option value='Pasaporte'>Pasaporte</option>
                    </select>
                    <p className='label-form-color'>N° Documento</p>
                    <input
                        required
                        type="text"
                        placeholder="Numero De Documento"
                        name="documentId"
                        value={documentId}
                        onChange={handleChange}
                        className='inputUpdate documentId'
                    />

                    <div className='container-bornDate'>
                        <label className='signin-label' for="bornDate">Fecha de nacimiento</label>
                        <input
                            type="date"
                            id="bornDate"
                            name="bornDate"
                            label='fecha de nacimiento'
                            value={bornDate}
                            onChange={handleChange}
                            className='inputUpdate bornDate'
                        />
                    </div>
                    <p className='label-form-color'>Género</p>
                    <select name="gender" className='inputUpdate genre' onChange={handleChange} >
                        <option value="" disabled selected>Genero</option>
                        <option defaultValue={dataPrev.gender} selected>{dataPrev.gender}</option>
                        <option value='Femenino'>Femenino</option>
                        <option value='Masculino'>Masculino</option>
                        <option value='Prefiero no decir'>Prefiero no decir</option>
                    </select>
                    <p className='label-form-color'>Celular</p>
                    <input
                        required
                        type='number'
                        min={10}
                        placeholder='Telefono'
                        name="phone"
                        value={phone}
                        onChange={handleChange}
                        className='inputUpdate phone'
                    />
                    <p className='label-form-color'>Contraseña</p>
                    <input
                        required
                        type='password'
                        placeholder='Contraseña'
                        name="password"
                        value={password}
                        onChange={handleChange}
                        className='inputUpdate password'
                    />
                </form>
                <button form='update-data-Form' className='config-userprofile-btn' type="submit">Enviar</button>
            </div>
        )
    }

    return (
        <div className='container-config-userprofile'>
            <section className='config-userprofile container-mygroup-rules'>
                <div className='config-userprofile-data1'>
                    <p><strong className='label-color'>Usuario : </strong>{dataPrev.userName}</p>
                    <p><strong className='label-color'>Nombre : </strong>{dataPrev.firstName}</p>
                    <p><strong className='label-color'>Apellido: </strong>{dataPrev.lastName}</p>
                    <p><strong className='label-color'>E-mail : </strong>{dataPrev.email}</p>
                </div>
                <div>
                    <p><strong>Tipo de documento : </strong>{dataPrev.typeDocument}</p>
                    <p><strong>N° documento : </strong>{dataPrev.documentId}</p>
                    <p><strong>Fecha de nacimiento : </strong>{dataPrev.bornDate}</p>
                    <p><strong>Genero : </strong>{dataPrev.gender}</p>
                </div>

            </section>
            <button className='config-userprofile-btn' onClick={openForm}> Actualizar datos </button>
            <div className='config-userprofile-form'>
                {open ? <UpdateForm /> : ""}
            </div>

        </div>
    );
}

export default ConfigForm;
