import React from 'react';
import Footer from '../../components/Footer/Footer';
import GroupInfo from '../../components/Group/GroupInfo/GroupInfo';
import Navbar from '../../components/Navbar/Navbar';

function Group() {

    return (
        <div className='main-container'>
            <Navbar />
            <GroupInfo />
            <Footer />
        </div>
    );
}

export default Group;