import React from 'react';
import LoanPaymentForm from "../../components/Deposit/LoanPaymentForm";
import Footer from "../../components/Footer/Footer";
import LoanForm from "../../components/Loan/LoanForm";
import Navbar from "../../components/Navbar/Navbar";
import UserSession from "../../components/UserSession/UserSession";

function Loan() {

    return (
        <div className='main-container'>
            <UserSession />
            <Navbar />
            <LoanForm />
            <Footer />
        </div>
    );
}

export default Loan;