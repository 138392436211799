import React from 'react';
import MexicoFlag from '../../img/Footer/MexicoFlag.png';
import './Footer.css';

function Footer() {
    return (
        <section className='container-footer'>
            <article className='footer-country'>
                <p>México</p>
                <img alt='mexico-flag' src={MexicoFlag}/>
            </article>
        </section>
    );
}

export default Footer;