import axios from "axios";
import React, { useState } from 'react';
import Swal from "sweetalert2";
import User from "../../img/Index/User.svg";
import "./LoginForm.css";
import { paths } from "../../Paths";

function LoginForm() {
  const [dataUser, setDataUser] = useState({
    userName: "",
    password: "",
    groupId: "",
  });

  //state de grupo
  const { userName, password } = dataUser;

  function handleChange(e) {
    setDataUser({
      ...dataUser,
      [e.target.name]: e.target.value,
    });

    console.log(dataUser.userName);
    console.log(dataUser.password);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(dataUser);

    if (dataUser.userName !== "" && dataUser.password !== "") {
      const options = {
        method: "POST",
        url: `${paths.api}/authenticate`,
        Headers: {
          "Content-Type": "aplication/json",
        },
        data: {
          userName: dataUser.userName,
          password: dataUser.password,
        },
      };
      await axios
        .request(options)
        .then(function (response) {
          console.log(response.data);

          localStorage.setItem("UserName", JSON.stringify(dataUser.userName));
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem("userId", JSON.stringify(response.data.id));
          localStorage.setItem(
            "groupId",
            JSON.stringify(response.data.groupId)
          );
          localStorage.setItem("role" , JSON.stringify(response.data.role));

          console.log("estado : ", response.status);
          console.log("token : ", response.data.token);
          console.log("Id usuario : ", response.data.id);
          console.log("data group id xd" + response.data.groupId);

          if (response.status === 200 || response.status === 201) {
            if (response.data.role === 1) {
              if (
                response.data.groupId == null ||
                response.data.groupId === "" ||
                response.data.groupId === 0
              ) {
                Swal.fire({
                  icon: "success",
                  title: "Genial...",
                  text: "Bienvenido!",
                  showConfirmButton: true,
                });
                window.location.href = "/group";
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Genial...",
                  text: "Bienvenido!",
                  showConfirmButton: true,
                });
                window.location.href = '/mygroup';
              }
            }else{
                window.location.href = '/admin';
            }
          }
        })
        .catch(function (error) {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Usuario no encontrado!",
          });
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Todos los campos son obligatorios!",
      });
    }
  };

  return (
    <form className="container-login-format">
      <img alt="user-icon" src={User} />
      <input
        type="text"
        placeholder="Nombre usuario"
        name="userName"
        value={userName}
        onChange={handleChange}
        className="login-input"
      />

      <input
        type="password"
        placeholder="Contraseña"
        name="password"
        value={password}
        onChange={handleChange}
        className="login-input"
      />
      <button className="login-button" type="submit" onClick={handleSubmit}>
        Ingresar
      </button>
    </form>
  );
}

export default LoginForm;
